import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import chunk from 'lodash/chunk'
import isEmpty from 'lodash/isEmpty'
import { withRouter } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import Icon from '../Icon/Icon'
import './Pagination.sass'

const Pagination = ({ data, setDisplayedData, ...router }) => {
  const [paginatedData, setPaginatedData] = useState([])
  const [pagesCount, setPagesCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(
    Object.prototype.hasOwnProperty.call(router.match.params, 'page')
      ? router.match.params.page
      : 1
  )

  const perPage = 15

  useEffect(() => {
    if (!isEmpty(data)) {
      const arrayOfChunks = chunk(data, perPage)
      const pages = Math.ceil(data.length / perPage)
      setPagesCount(pages)
      setPaginatedData(arrayOfChunks)
      const pageData = arrayOfChunks[currentPage - 1]
      setDisplayedData(pageData || arrayOfChunks[0])
    } else {
      setDisplayedData([])
      setPagesCount(0)
      setPaginatedData([])
    }
  }, [data])

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(router.match.params, 'page')) {
      const curPage = router.match.params.page
      setCurrentPage(curPage)
      if (!isEmpty(paginatedData)) {
        setDisplayedData(paginatedData[curPage - 1])
      }
    } else {
      setCurrentPage(1)
    }
  }, [router.match.params, paginatedData])

  const onPageClick = ({ selected }) => {
    router.history.push(`/orders/page/${selected + 1}`)
  }

  return (
    <ReactPaginate
      previousLabel={<Icon name="angle-left" size="14" />}
      nextLabel={<Icon name="angle-right" size="14" />}
      breakLabel="..."
      breakClassName="break-me"
      pageCount={pagesCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={onPageClick}
      containerClassName="Pagination-Container"
      subContainerClassName="pages pagination"
      activeClassName="active"
      forcePage={currentPage - 1}
    />
  )
}

Pagination.propTypes = {
  data: PropTypes.array.isRequired,
  setDisplayedData: PropTypes.func.isRequired,
}

export default withRouter(Pagination)
