import React from 'react'
import './ReplyMessage.sass'
import { useState } from 'react'
import { createForm } from '../../../utils/newforms/createForm'
import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'
import FormRender from '../../../utils/newforms/render/FormRender'

function ReplyMessage(data) {
  const text = `
      <p>
        Уважаемый ${data.userFio}, Вы забронировали автомобиль ( далее ТС) марки
        <b>${data.car}</b>
      </p>
      <p>
        ПРОСИМ ВАС ВНИМАТЕЛЬНО ОЗНАКОМИТЬСЯ С УСЛОВИЯМИ И ПРАВИЛАМИ АРЕНДЫ ТС НА
        НАШЕМ САЙТЕ!!!
        <a href="http://autoprokat51.ru/#rules">
          http://autoprokat51.ru/#rules
        </a>
      </p>
      <p>
        ОБРАЩАЕМ ВАШЕ ВНИМАНИЕ, ЕСЛИ У ВАС ЕСТЬ ПЛАНЫ ВЫЕЗДА в пос.ТЕРИБЕРКА
        сообщите об этом. НЕ все ТС из нашего парка разрешено эксплуатировать в
        этом районе.
      </p>
      <p>
        На полуостров РЫБАЧИЙ, СРЕДНИЙ, горный массив Хибины, пос. Дальние
        Зеленцы и другие поселения и места, куда проезд проходит по дорогам , не
        приспособленным для проезда неподготовленного ТС - ВЫЕЗД ЗАПРЕЩЁН!!
        Подробности уточняйте у Администратора АВТОПРОКАТ51
      </p>
      <p>
        Если у Вас возникнут вопросы или замечания, просим Вас сообщать о них
        ЗАБЛАГОВРЕМЕННО, и ДО НАЧАЛА АРЕНДЫ ТС!!!! Это позволит сэкономить Ваше
        время, а так же оперативно решить возникшие разногласия.
      </p>
      <p>
        <b>Начало аренды</b> – ${data.delDate} в ${data.delTime}
      </p>
      <p>
        <b>Окончание аренды</b> – ${data.retDate} в ${data.retTime}
      </p>
      <p>
        <b>Место получения автомобиля</b> - ${data.delType}
      </p>
      <p>
        <b>Место возврата автомобиля</b> – ${data.retType}
      </p>
      <ul>
        <p>
          <b>Расчет стоимости аренды ТС:</b>
        </p>
        <li>Тариф аренды ТС – ${data.rentPrice} р/сут</li>
        <li>Стоимость аренды – ${data.rentPrice} * ${data.fullDays} сут = ${
    data.rentPrice * data.fullDays
  } рублей</li>
        <li>Подача ТС – ${data.delCost} рублей</li>
        <li>Возврат ТС – ${data.retCost} рублей</li>
        <li>Мойка ТС* = ${data.carwash} рублей</li>
        <li>Залог за ТС** = ${data.zalog} рублей</li>
      </ul>
      <p>
        <b>Доп. опции:</b>
      </p>
      ${data.additionalOptions}<br/>
      <p>
        Общая стоимость аренды ТС с учетом дополнительных услуг и залога = ${
          data.priceToPay
        }
        рублей
      </p>
      <p>
        * В случае возврата ТС в грязном виде арендатор оплачивает арендодателю
        стоимость услуги мойки ( от ${data.carwash} р.)
      </p>
      <p>
        ** ЗАЛОГ ЗА ТС ВНОСИТСЯ ИСКЛЮЧИТЕЛЬНО НАЛИЧНЫМИ ДЕНЕЖНЫМИ СРЕДСТВАМИ.
        ВОЗВРАТ ЗАЛОГА ЗА ТС ПРОИЗВОДИТСЯ ПО ФАКТУ ВОЗВРАТА ТС В ПАРК
        АРЕНДОДАТЕЛЯ, И ПЕРЕДАЕТСЯ ВАМ ТАК ЖЕ НАЛИЧНЫМИ ДЕНЕЖНЫМИ СРЕДСТВАМИ!!!!
      </p>
      <p>СУТОЧНОЕ ОГРАНИЧЕНИЕ ПРОБЕГА ТС СОСТАВЛЯЕТ 350 КМ. В СУТКИ.</p>
      <p>
        Если у вас будет перепробег - Оплачиваете его по факту сдачи ТС, из
        расчета 10 рублей за 1 км.
      </p>
      <p>
        <b>ВАШ ЛИМИТ – ${data.fullDays * 350} КМ</b>
      </p>
      <p>
        По независящим от нас обстоятельствам возможна замена марки автомобиля.
        Мы максимально стараемся выполнить договоренности по данному
        бронированию. Предварительное бронирование не является договором
        проката.
      </p>
      <p>ВНИМАНИЕ!!!</p>
      <p>ОБЯЗАТЕЛЬНО СООБЩИТЕ НАМ, ЕСЛИ У ВАС ИЗМЕНЯТСЯ ПЛАНЫ!!!</p>
      <p>При необходимости, Вы можете связаться с нами:</p>
      <p>
        тел.: <a href="tel:+7 (8152) 75-11-88">+7 (8152) 75-11-88</a> или
        <a href="+7 902-281-11-88">+7 902-281-11-88</a>
      </p>
      <p>
        e-mail: <a href="mailto:autoprokat51@mail.ru">autoprokat51@mail.ru</a>
      </p>
      <p>
        <a href="http://autoprokat51.ru">http://autoprokat51.ru</a>
      </p>
  `

  const [form, setForm] = useState(createForm({ formPattern: new Form(text) }))
  return (
    <div className="ReplyMessage">
      <FormRender
        sections={[
          {
            fields: ['text'],
          },
        ]}
        form={form}
        setForm={setForm}
      />
    </div>
  )
}

class Form {
  constructor(text) {
    this.text = {
      field: {
        fieldId: 'text',
        fieldType: 'texteditor',
        label: 'Форма для ответа',
        value: text,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default ReplyMessage
