import React from 'react'
import { Route } from 'react-router-dom'
import RulesList from './RulesList/RulesList'
import RulesManager from './RulesManager/RulesManager'

function Routes() {
  return (
    <>
      <Route path="/rules" exact component={RulesList} />
      <Route path="/rules/add" exact component={RulesManager} />
      <Route path="/rules/edit/:id" exact component={RulesManager} />
    </>
  )
}

export default Routes
