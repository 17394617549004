import React, { useEffect, useState } from 'react'
import RouteComponent from '../../RouteComponent/RouteComponent'
import Spinner from '../../../ui/Spinner/Spinner'
import InfoLine from '../../../ui/InfoLine/InfoLine'
import './OrdersList.sass'
import { isEmpty, orderBy } from 'lodash'
import Icon from '../../../ui/Icon/Icon'
import { withRouter } from 'react-router'
import { createForm } from '../../../utils/newforms/createForm'
import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'
import FormRender from '../../../utils/newforms/render/FormRender'
import useFilterObjValues from '../../../hooks/useFilterObjValues'
import getCollection from '../../../utils/db/getCollection'
import promiseAllValues from '../../../utils/promiseAllValues'
import Pagination from '../../../ui/Pagination/Pagination'
import parseOrderData from '../OrderDetails/functions/parseOrderData'

function Orders({ ...router }) {
  const [ordersArr, setOrdersArr] = useState(null)
  const [form, setForm] = useState(
    createForm({
      formPattern: {
        search: {
          field: {
            fieldId: 'search',
            fieldType: 'input',
            inputType: 'text',
            label: 'Поиск по заказам',
            icon: 'search',
            iconPosition: 'left',
          },
          render: getFieldRenderObject(),
        },
      },
    })
  )

  const [filteredOptions, setSearchFieldVal] = useFilterObjValues(ordersArr)
  const [displayedData, setDisplayedData] = useState([])

  useEffect(() => {
    setSearchFieldVal(form.search.value)
  }, [form.search.value])

  useEffect(() => {
    promiseAllValues({
      orders: getCollection({ path: 'newOrders', docIdName: 'orderId' }),
      cars: getCollection({ path: 'cars', docIdName: 'carId' }),
    }).then((result) => {
      const sortedOrders = orderBy(
        result.orders,
        ['created'],
        ['desc']
      ).map((o) => parseOrderData({ data: o, cars: result.cars }))

      setOrdersArr(sortedOrders)
    })
  }, [])

  return (
    <RouteComponent title="Заказы">
      <div className="OrdersList">
        <FormRender
          sections={[{ fields: ['search'] }]}
          form={form}
          setForm={setForm}
        />

        {filteredOptions ? (
          isEmpty(filteredOptions) ? (
            <p className="EmptyText">Нет заказов</p>
          ) : (
            displayedData.map((o) => (
              <OrderTile
                key={o.id}
                {...o}
                onEdit={() => router.history.push(`/orders/view/${o.orderId}`)}
              />
            ))
          )
        ) : (
          <Spinner type="module" />
        )}
        <Pagination
          data={filteredOptions}
          setDisplayedData={setDisplayedData}
          parentPath={router.match.path}
        />
      </div>
    </RouteComponent>
  )
}

function OrderTile(data) {
  return (
    <div className="OrderTile" onDoubleClick={data.onEdit}>
      <div className="OrderDate">{data.created}</div>
      <div className="Content">
        <p className="Title">{data.car}</p>
        <InfoLine
          title="Клиент"
          content={`${data.userFio}, ${data.userPhone}`}
        />
        <InfoLine
          title="Срок аренды"
          content={`${data.delDate} в ${data.delTime} - ${data.retDate} в ${data.retTime} (полных дней: ${data.fullDays}, часов: ${data.fullHours})`}
        />
        <InfoLine
          title="Подача и возврат"
          content={`${data.delType} (${data.delCost} руб.) - ${data.retType} (${data.retCost} руб.)`}
        />
      </div>

      <div className="Options">
        <div className="OptionButton Accent" onClick={data.onEdit}>
          <Icon name="list" weight="solid" />
          <p>Открыть заявку</p>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Orders)
