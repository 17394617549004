import React from 'react'
import './Nav.sass'
import { NavLink } from 'react-router-dom'
import Icon from '../../../ui/Icon/Icon'
import { firebase } from '../../../config/firebase'

function Nav() {
  return (
    <div className="Nav">
      <div className="NavBody">
        <NavElement title="Заказы" icon="list" link="/orders" />
        <NavElement title="Авто" icon="cars" link="/cars" />
        <NavElement title="Опции" icon="stream" link="/options" />
        <NavElement title="Условия" icon="list-alt" link="/rules" />
        <NavElement title="Другое" icon="cog" link="/settings" />
      </div>

      <NavElement
        title="Выход"
        icon="sign-out-alt"
        onClick={() => firebase.auth().signOut()}
      />
    </div>
  )
}

function NavElement({ title, icon, link, onClick }) {
  const content = (
    <>
      <div className="NavElement-Icon">
        <Icon name={icon} weight="solid" />
      </div>
      <p className="Title">{title}</p>
    </>
  )
  return onClick ? (
    <div className="NavElement" onClick={onClick}>
      {content}
    </div>
  ) : (
    <NavLink
      to={link}
      className="NavElement"
      activeClassName="NavElement-Active"
    >
      {content}
    </NavLink>
  )
}

export default Nav
