import React from 'react'
import './Home.sass'
import Nav from './Nav/Nav'
import Routes from './Routes'

function Home() {
  return (
    <div className="Home">
      <Nav />
      <Routes />
    </div>
  )
}

export default Home
