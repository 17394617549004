import React from 'react'
import { Route } from 'react-router'
import Orders from '../../components/Orders/index'
import Cars from '../../components/Cars/index'
import Options from '../../components/Options/index'
import Rules from '../../components/Rules/index'
import Settings from '../../components/Settings/Settings'

function Routes() {
  return (
    <div className="Routes">
      <Route path="/" component={Orders} />
      <Route path="/cars" component={Cars} />
      <Route path="/options" component={Options} />
      <Route path="/rules" component={Rules} />
      <Route path="/settings" component={Settings} />
    </div>
  )
}

export default Routes
