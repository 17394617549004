import React, { useEffect, useState } from 'react'
import RouteComponent from '../../RouteComponent/RouteComponent'
import Spinner from '../../../ui/Spinner/Spinner'
import InfoLine from '../../../ui/InfoLine/InfoLine'
import './CarsList.sass'
import { find, isEmpty, orderBy, toNumber } from 'lodash'
import Icon from '../../../ui/Icon/Icon'
import { withRouter } from 'react-router'
import { createForm } from '../../../utils/newforms/createForm'
import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'
import FormRender from '../../../utils/newforms/render/FormRender'
import useFilterObjValues from '../../../hooks/useFilterObjValues'
import Button from '../../../ui/Button/Button'
import { db } from '../../../config/firebase'
import { formatDateAsDayMontYearHHMM } from '../../../utils/date/dateFormat'
import Img from '../../../ui/Img/Img'
import DeletePopUp from '../../../ui/PopUp/DeletePopUp/DeletePopUp'
import onCarDelete from '../CarsManager/functions/deleteCar'
import getCollection from '../../../utils/db/getCollection'

function Orders({ ...router }) {
  const [carsArr, setCarsArr] = useState(null)
  const [form, setForm] = useState(
    createForm({
      formPattern: {
        search: {
          field: {
            fieldId: 'search',
            fieldType: 'input',
            inputType: 'text',
            label: 'Поиск по авто',
            icon: 'search',
            iconPosition: 'left',
          },
          render: getFieldRenderObject(),
        },
      },
    })
  )
  const [deleteCar, setDeleteCar] = useState(null)
  const [filteredOptions, setSearchFieldVal] = useFilterObjValues(carsArr)

  useEffect(() => {
    setSearchFieldVal(form.search.value)
  }, [form.search.value])

  useEffect(() => {
    db.collection('cars').onSnapshot((docs) => {
      const data = []
      docs.forEach((d) => data.push({ carId: d.id, ...d.data() }))
      setCarsArr(orderBy(data, ['title'], ['asc']))
    })
  }, [])

  const syncCars = () => {
    getCollection({ path: 'cars', docIdName: 'car' }).then((data) => {
      const filtered = data.filter((o) => o.isPublished && !o.isDeleted && true)

      const modified = []
      filtered.forEach((car) => {
        car.price.forEach((cp) => {
          modified.push({
            ...car,
            photo: car.photos[0].publicUrl,
            price: cp,
          })
        })
      })
      const json = JSON.stringify({ cars: modified })
      fetch(
        'https://autoprokat51.ru/syncCars.php?token=b2a23e0e779cc697a90a283a54ff898c',
        {
          method: 'POST',
          body: json,
        }
      ).then((resp) => {
        console.log('🚀 ~ file: CarsList.jsx:77 ~ ).then ~ resp', resp.json())
      })
    })
  }

  return (
    <RouteComponent
      title="Автомобили"
      controlls={
        <>
          <Button
            title="Синхронизировать авто"
            icon="cloud-upload"
            theme="bounded"
            border="gray"
            color="gray"
            size={28}
            style={{ marginRight: '16px' }}
            onClick={syncCars}
          />
          <Button
            title="Добавить авто"
            icon="plus"
            fill="accent"
            theme="solid"
            type="navlink"
            path="/cars/add"
            size={28}
          />
        </>
      }
    >
      {deleteCar && (
        <DeletePopUp
          title="Удаление автомобиля"
          warningMessage={`Вы уверены, что хотите удалить авто "${deleteCar.title}"?`}
          closePopUp={() => setDeleteCar(null)}
          submitDelete={() => onCarDelete({ carId: deleteCar.carId })}
        />
      )}
      <div className="CarsList">
        <FormRender
          sections={[{ fields: ['search'] }]}
          form={form}
          setForm={setForm}
        />
        {filteredOptions ? (
          isEmpty(filteredOptions) ? (
            <p className="EmptyText">Нет авто</p>
          ) : (
            filteredOptions.map((o) => (
              <CarTile
                key={o.id}
                {...o}
                onEdit={() => router.history.push(`/cars/edit/${o.carId}`)}
                onDelete={() =>
                  setDeleteCar({
                    title: o.title,
                    carId: o.carId,
                  })
                }
              />
            ))
          )
        ) : (
          <Spinner type="module" />
        )}
      </div>
    </RouteComponent>
  )
}

function CarTile(data) {
  return (
    <div className="CarTile" onDoubleClick={data.onEdit}>
      <div className="CarDate">
        {formatDateAsDayMontYearHHMM(data.updated)}
        <br />
        {!data.isPublished && (
          <span className="PublishTag">Не опубликован</span>
        )}
      </div>
      <div className="Avatar">
        <Img src={find(data.photos, ['isAvatar', true]).publicUrl} />
      </div>
      <div className="Content">
        <p className="Title">{data.title}</p>
        <InfoLine title="Класс" content={`${data.class}`} />
        <InfoLine title="Модельный ряд" content={`${data.modelYear}`} />
      </div>

      <div className="Options">
        <div className="OptionButton Accent" onClick={data.onEdit}>
          <Icon name="pencil-alt" weight="solid" />
        </div>
        <div className="OptionButton Red" onClick={data.onDelete}>
          <Icon name="trash-alt" />
        </div>
      </div>
    </div>
  )
}

export default withRouter(Orders)
