import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import CarsList from './CarsList/CarsList'
import CarsManager from './CarsManager/CarsManager'

function Routes() {
  return (
    <>
      <Route path="/cars" exact component={CarsList} />
      <Route path="/cars/add" exact component={CarsManager} />
      <Route path="/cars/edit/:id" exact component={CarsManager} />
    </>
  )
}

export default Routes
