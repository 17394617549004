import React, { useEffect, useState } from 'react'
import RouteComponent from '../../RouteComponent/RouteComponent'
import Spinner from '../../../ui/Spinner/Spinner'
import InfoLine from '../../../ui/InfoLine/InfoLine'
import './OptionsList.sass'
import { isEmpty, orderBy } from 'lodash'
import Icon from '../../../ui/Icon/Icon'
import { withRouter } from 'react-router'
import Button from '../../../ui/Button/Button'
import { db } from '../../../config/firebase'
import { formatDateAsDayMontYearHHMM } from '../../../utils/date/dateFormat'
import DeletePopUp from '../../../ui/PopUp/DeletePopUp/DeletePopUp'
import onOptionDelete from '../OptionsManager/functions/deleteOption'
import getCollection from '../../../utils/db/getCollection'
import axios from 'axios'

function OptionsList({ ...router }) {
  const [optionsArr, setOptionsArr] = useState(null)
  const [deleteOption, setDeleteOption] = useState(null)

  useEffect(() => {
    db.collection('options').onSnapshot((docs) => {
      const data = []
      docs.forEach((d) => data.push({ optionId: d.id, ...d.data() }))
      setOptionsArr(
        orderBy(
          data.filter((d) => !d.isDeleted && true),
          ['position'],
          ['asc']
        )
      )
    })
  }, [])

  const syncOptions = () => {
    getCollection({ path: 'options', docIdName: 'optionId' }).then((data) => {
      const filtered = data.filter((o) => o.isActive && !o.isDeleted && true)
      const json = JSON.stringify({ options: filtered })
      fetch(
        'https://autoprokat51.ru/syncOptions.php?token=b2a23e0e779cc697a90a283a54ff898c',
        {
          method: 'POST',
          body: json,
        }
      ).then((resp) => {
        console.log(
          '🚀 ~ file: OptionsList.jsx:46 ~ ).then ~ resp',
          resp.json()
        )
      })
    })
  }

  return (
    <RouteComponent
      title="Дополнительные опции"
      controlls={
        <>
          <Button
            title="Синхронизировать опции"
            icon="cloud-upload"
            theme="bounded"
            border="gray"
            color="gray"
            size={28}
            style={{ marginRight: '16px' }}
            onClick={syncOptions}
          />
          <Button
            title="Добавить опцию"
            icon="plus"
            fill="accent"
            theme="solid"
            type="navlink"
            path="/options/add"
            size={28}
          />
        </>
      }
    >
      {deleteOption && (
        <DeletePopUp
          title="Удаление опции"
          warningMessage={`Вы уверены, что хотите удалить опцию "${deleteOption.title}"?`}
          closePopUp={() => setDeleteOption(null)}
          submitDelete={() =>
            onOptionDelete({ optionId: deleteOption.optionId })
          }
        />
      )}
      <div className="OptionsList">
        {optionsArr ? (
          isEmpty(optionsArr) ? (
            <p className="EmptyText">Нет опций</p>
          ) : (
            optionsArr.map((o) => (
              <OptionTile
                key={o.optionId}
                {...o}
                onEdit={() =>
                  router.history.push(`/options/edit/${o.optionId}`)
                }
                onDelete={() =>
                  setDeleteOption({
                    title: o.title_ru,
                    optionId: o.optionId,
                  })
                }
              />
            ))
          )
        ) : (
          <Spinner type="module" />
        )}
      </div>
    </RouteComponent>
  )
}

function OptionTile(data) {
  return (
    <div className="OptionTile" onDoubleClick={data.onEdit}>
      <div className="CarDate">
        {formatDateAsDayMontYearHHMM(data.updated)}
        <br />
        {!data.isActive && <span className="PublishTag">Не активна</span>}
      </div>
      <div className="Content">
        <p className="Title">{data.title_ru}</p>
        <p className="Description">{data.description_ru}</p>
        <InfoLine
          title="Цена"
          content={`${
            +data.price === 0 ? 'Бесплатно' : data.price + ' руб.'
          } / ${data.priceType === 'once' ? 'единоразово' : 'сутки'} `}
        />
      </div>

      <div className="Options">
        <div className="OptionButton Accent" onClick={data.onEdit}>
          <Icon name="pencil-alt" weight="solid" />
        </div>
        <div className="OptionButton Red" onClick={data.onDelete}>
          <Icon name="trash-alt" />
        </div>
      </div>
    </div>
  )
}

export default withRouter(OptionsList)
