import React, { useEffect, useState } from 'react'
import './Settings.sass'
import { withRouter } from 'react-router'
import StatusMessage, {
  useStatusMessage,
} from '../../ui/StatusMessage/StatusMessage'
import Button from '../../ui/Button/Button'
import FormRender from '../../utils/newforms/render/FormRender'
import getFieldRenderObject from '../../utils/newforms/render/getFieldRenderObject'
import RouteComponent from '../../components/RouteComponent/RouteComponent'
// import { createForm } from '../../../utils/newforms/createForm'
import isFormValid from '../../utils/newforms/validation/isFormValid'
import { isNull } from 'lodash'
import getFormValues from '../../utils/newforms/getFormValues'
import { updateDoc } from '../../utils/db/updateDoc'
// import addDoc from '../../../utils/db/addDoc'
// import getDoc from '../../../utils/db/getDoc'
import Spinner from '../../ui/Spinner/Spinner'
import getDoc from '../../utils/db/getDoc'
import { createForm } from '../../utils/newforms/createForm'

function Settings({ ...router }) {
  const [form, setForm] = useState(null)

  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getDoc({ path: 'settings', docId: 'BqOykJugZ5mnBAAPocze' }).then((data) =>
      setForm(
        createForm({
          formPattern: new SettingsForm(),
          formData: data,
        })
      )
    )
  }, [])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля формы',
        closeAfter: 5000,
      })
    }
    setIsLoading(true)
    const data = getFormValues({ form })

    updateDoc({ path: 'settings', docId: 'BqOykJugZ5mnBAAPocze', data })
      .then((docId) => {
        setIsLoading(false)
        const json = JSON.stringify({ settings: data })
        fetch(
          'https://autoprokat51.ru/syncSettings.php?token=b2a23e0e779cc697a90a283a54ff898c',
          {
            method: 'POST',
            body: json,
          }
        ).then((resp) => {
          setStatusMessage({
            show: true,
            type: 'success',
            message: 'Данные сохранены',
            closeAfter: 5000,
          })
          window.scrollTo({ top: 0, behavior: 'smooth' })

          console.log(
            '🚀 ~ file: SettingsSync.jsx:46 ~ ).then ~ resp',
            resp.json()
          )
        })
      })

      .catch((error) => {
        console.log(
          '🚀 ~ file: Settings.jsx ~ line 79 ~ onSubmit ~ error',
          error
        )
      })
  }

  return (
    <RouteComponent title={'Настройки'}>
      {!isNull(form) ? (
        <div className="Settings-Content">
          {statusMessage.show && (
            <StatusMessage
              className="Site-StatusMessage"
              type={statusMessage.type}
              message={statusMessage.message}
            />
          )}

          <p className="Title">Подача/возврат авто</p>
          <div className="MainInfo">
            <FormRender
              sections={[
                {
                  fields: [
                    'officeBusy',
                    'airBusy',
                    'otherBusy',
                    'officeOther',
                    'airOther',
                    'otherOther',
                  ],
                },
              ]}
              form={form}
              setForm={setForm}
              errors={showErrors}
            />
          </div>
          <p className="Title">Другое</p>
          <FormRender
            sections={[
              {
                fields: ['carwash'],
              },
            ]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />

          <div className="Buttons">
            <Button
              title="Сохранить"
              theme="solid"
              fill="accent"
              iconPosition="right"
              size={48}
              isLoading={isLoading}
              onClick={onSubmit}
            />
            <Button
              title="Закрыть"
              theme="bounded"
              color="gray"
              border="gray"
              size={48}
              isLoading={isLoading}
              onClick={() => router.history.push('/rules/')}
            />
          </div>
        </div>
      ) : (
        <Spinner type="module" />
      )}
    </RouteComponent>
  )
}

class SettingsForm {
  constructor() {
    this.carwash = {
      field: {
        fieldId: 'carwash',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'Стоимость мойки',
      },
      render: getFieldRenderObject(),
    }
    this.officeBusy = {
      field: {
        fieldId: 'officeBusy',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'В офис (раб. время)',
      },
      render: getFieldRenderObject(),
    }
    this.officeOther = {
      field: {
        fieldId: 'officeOther',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'В офис (ночн. время)',
      },
      render: getFieldRenderObject(),
    }
    this.airBusy = {
      field: {
        fieldId: 'airBusy',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'В аэропорт (раб. время)',
      },
      render: getFieldRenderObject(),
    }
    this.airOther = {
      field: {
        fieldId: 'airOther',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'В аэропорт (ночн. время)',
      },
      render: getFieldRenderObject(),
    }
    this.otherBusy = {
      field: {
        fieldId: 'otherBusy',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'На другой адрес (раб. время)',
      },
      render: getFieldRenderObject(),
    }
    this.otherOther = {
      field: {
        fieldId: 'otherOther',
        fieldType: 'input',
        inputType: 'text',
        required: true,
        label: 'На другой адрес (ночн. время)',
      },
      render: getFieldRenderObject(),
    }
  }
}

export default withRouter(Settings)
