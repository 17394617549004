import { find, toNumber } from 'lodash'
import {
  formatDateAsDayMontYearHHMM,
  formatDateAsDayMonthYear,
} from '../../../../utils/date/dateFormat'
import parseLocationType from '../../OrderManager/functions/parseLocationType'

const parseOrderData = ({ data, cars, options }) => {
  const car = find(cars, ['carId', data.carId])
  const parsedCar = car
    ? `${car.title} ${car.modelYear} ${data.transmission}КПП`
    : 'Автомобиль удален'
  return {
    ...data,
    orderId: data.orderId,
    car: parsedCar,
    userFio: data.userFio,
    userPhone: data.userPhone.metadata.formatInternational,
    created: formatDateAsDayMontYearHHMM(data.created),
    delDate: formatDateAsDayMonthYear(data.delDate),
    delTime: data.delTime,
    retDate: formatDateAsDayMonthYear(data.retDate),
    retTime: data.retTime,
    fullDays: data.counters.fullDays,
    fullHours: data.counters.fullHours,
    delType: parseLocationType(data.delType),
    retType: parseLocationType(data.retType),
    delLocation: data.delType === 'other' ? data.delLocation : null,
    retLocation: data.retType === 'other' ? data.retLocation : null,
    delCost: data.counters.delivery,
    retCost: data.counters.return,
    rentPrice: data.counters.rentPrice,
    carwash: data.counters.carwash,
    zalog: data.counters.zalog,
    additionalOptions: 'Нет опций',
    priceToPay: countOrderPrice(data),
  }
}

export function countOrderPrice(data) {
  const {
    carwash,
    delivery,
    fullDays,
    options,
    rentPrice,
    return: ret,
    zalog,
  } = data.counters

  return (
    toNumber(carwash) +
    fullDays * toNumber(rentPrice) +
    options +
    delivery +
    ret +
    toNumber(zalog)
  )
}

export default parseOrderData
