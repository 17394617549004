import React, { useEffect, useState } from 'react'
import './OrderDetails.sass'
import { withRouter } from 'react-router'
import Button from '../../../ui/Button/Button'
import RouteComponent from '../../RouteComponent/RouteComponent'
import { isEmpty, isNull, split } from 'lodash'
import Spinner from '../../../ui/Spinner/Spinner'
import InfoLine from '../../../ui/InfoLine/InfoLine'
import ReplyMessage from './ReplyMessage'
import promiseAllValues from '../../../utils/promiseAllValues'
import getCollection from '../../../utils/db/getCollection'
import { formatDateAsDayMonthNameYear } from '../../../utils/date/dateFormat'
import parseOrderData from './functions/parseOrderData'
import DeletePopUp from '../../../ui/PopUp/DeletePopUp/DeletePopUp'
import { db } from '../../../config/firebase'
import getDoc from '../../../utils/db/getDoc'

function OrderDetails({ ...router }) {
  const orderId = router.match.params.id
  const [data, setData] = useState(null)
  const [deleteOrder, setDeleteOrder] = useState(null)

  useEffect(() => {
    promiseAllValues({
      order: getDoc({
        path: 'newOrders',
        docId: orderId,
        docIdName: 'orderId',
      }),
      cars: getCollection({ path: 'cars', docIdName: 'carId' }),
      options: getCollection({ path: 'options', docIdName: 'optionId' }),
      settings: getDoc({
        path: 'settings',
        docId: 'BqOykJugZ5mnBAAPocze',
        docIdName: 'id',
      }),
    }).then((result) =>
      setData(
        parseOrderData({
          data: result.order,
          cars: result.cars,
          options: result.options,
        })
      )
    )
  }, [])

  const onOrderDelete = ({ orderId }) =>
    db
      .collection('orders')
      .doc(orderId)
      .delete()
      .then(() => {
        window.location.reload()
      })

  const onDocumentTemplateCreate = () => {
    const dateFrom = split(data.dateFrom, '.')

    const documentObject = {
      dogNumber: split(data.dateFrom, '.').join(''),
      dogDate: formatDateAsDayMonthNameYear({
        seconds: new Date(dateFrom[2], dateFrom[1], dateFrom[0]) / 1000,
      }),
      userFullName: data.userFio,
      userAddress: data.passportAddress,
      userFact: data.userFact,
      userPhone: data.userPhone,
      userBdate: data.userBdate,
      userPassportSeria: `${data.passportSeria}, выдан: ${data.passportDate}`,
      userPassportKem: data.passportKem,
      vodSeria: data.vodSeria,
      deliveryFullInfo: `${data.dateFrom} в ${data.timeFrom}, ${data.delLocation}`,
      otpFullInfo: `${data.dateTo} в ${data.timeTo}, ${data.otpLocation}`,
    }

    const json = JSON.stringify({ document: documentObject })
    fetch(
      'https://autoprokat51.ru/functions/templator.php?token=b2a23e0e779cc697a90a283a54ff898c',
      {
        method: 'POST',
        body: json,
      }
    )
      .then((resp) => {
        console.log('🚀 ~ file: CarsList.jsx:77 ~ ).then ~ resp', resp.json())
        return new Promise((r) => setTimeout(r, 2000))
      })
      .then(() =>
        window.open(
          'https://autoprokat51.ru/functions/exported_file.xlsx',
          '_blank'
        )
      )
  }

  return (
    <RouteComponent
      title={
        !isEmpty(data) ? `Детали заказа - от ${data.created} | ${data.car}` : ''
      }
      controlls={
        <p className="OrderDetails-Buttons">
          <Button
            title="Создать договор"
            icon="file"
            theme="bounded"
            color="gray"
            border="gray"
            onClick={() => onDocumentTemplateCreate()}
            size={28}
          />
          <Button
            title="Редактировать заявку"
            icon="pencil-alt"
            fill="accent"
            theme="solid"
            type="navlink"
            path={`/orders/edit/${orderId}`}
            size={28}
          />
          {data && data.isModified ? (
            <Button
              title="Удалить изменения"
              icon="trash-alt"
              theme="solid"
              fill="noty"
              onClick={() =>
                setDeleteOrder({
                  title: `Заказ от ` + data.created + ` ` + data.car,
                  orderId: data.orderId,
                })
              }
              size={28}
            />
          ) : null}
        </p>
      }
    >
      <div className="OrderDetails-Content">
        {deleteOrder && (
          <DeletePopUp
            title="Удаление изменений по заказу"
            warningMessage={`Вы уверены, что хотите удалить изменения по заказу "${deleteOrder.title}"?`}
            closePopUp={() => setDeleteOrder(null)}
            submitDelete={() => onOrderDelete({ orderId: data.orderId })}
          />
        )}
        {!isEmpty(data) ? (
          <>
            <div className="Order">
              <p className="Title">{data.car}</p>
              <InfoLine
                title="Срок аренды"
                content={`${data.delDate} в ${data.delTime} - ${
                  data.retDate
                } в ${
                  data.retTime
                } ${`(полных дней: ${data.fullDays}, часов: ${data.fullHours})`}`}
              />
              <InfoLine
                title="Подача и возврат"
                content={`${data.delType} (${data.delCost} руб.) - ${data.retType} (${data.retCost} руб.)`}
              />
              {!isNull(data.delLocation) && (
                <InfoLine
                  title="Адрес подачи"
                  content={`${data.delLocation}`}
                />
              )}
              {!isNull(data.retLocation) && (
                <InfoLine
                  title="Адрес возврата"
                  content={`${data.retLocation}`}
                />
              )}
              <InfoLine
                title="Стоимость аренды в сутки"
                content={`${data.rentPrice} руб.`}
              />
              <InfoLine title="Срок аренды" content={`${data.fullDays} сут.`} />
              <InfoLine
                title="Стоимость подачи ТС"
                content={`${data.delCost} руб.`}
              />
              <InfoLine
                title="Стоимость возврата ТС"
                content={`${data.retCost} руб.`}
              />
              <InfoLine title="Мойка" content={`${data.carwash} руб.`} />
              <InfoLine
                title="Стоимость залога"
                content={`${data.zalog} руб.`}
              />
              <InfoLine title="Доп. опции" content={data.additionalOptions} />
              <InfoLine
                title="Общая стоимость по договору"
                content={`${data.priceToPay} руб.`}
              />
              <InfoLine
                title="Способ оплаты"
                content={`${
                  data.paymentType === 'cash'
                    ? 'наличными при получении'
                    : 'онлайн оплата'
                }`}
              />
            </div>
            <div className="Client">
              <p className="Title">{data.userFio}</p>
              <InfoLine title="Телефон" content={`${data.userPhone}`} />
              <InfoLine title="Дата рождения" content={`${data.userBdate}`} />
              <InfoLine title="E-mail" content={`${data.userEmail}`} />
              <InfoLine
                title="Адрес регистрации"
                content={`${data.passportAddress}`}
              />
              <InfoLine title="Адрес проживания" content={`${data.userFact}`} />
              <InfoLine
                title="Доп. комментарий"
                content={`${data.userComment ? data.userComment : '-'}`}
              />
              <InfoLine
                className={'Passport'}
                title="Паспорт"
                content={`${data.passportSeria}, выдан: ${data.passportKem} ${data.passportDate}`}
              />
              <InfoLine
                title="Вод. удостоверение"
                content={`${data.vodSeria}, вод. стаж: ${data.vodStage}`}
              />
            </div>
            <ReplyMessage {...data} />
          </>
        ) : (
          <Spinner type="module" />
        )}
        <div className="Buttons">
          <Button
            title="Закрыть"
            theme="bounded"
            color="gray"
            border="gray"
            size={48}
            onClick={() => router.history.push('/orders/')}
          />
        </div>
      </div>
    </RouteComponent>
  )
}

export default withRouter(OrderDetails)
