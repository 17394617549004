import React, { useContext, useState } from 'react'
import Button from '../../ui/Button/Button'
import { createForm } from '../../utils/newforms/createForm'
import FormRender from '../../utils/newforms/render/FormRender'
import isFormValid from '../../utils/newforms/validation/isFormValid'
import getFormValues from '../../utils/newforms/getFormValues'
import getFieldRenderObject from '../../utils/newforms/render/getFieldRenderObject'
import StatusMessage, {
  useStatusMessage,
} from '../../ui/StatusMessage/StatusMessage'
import './Auth.sass'
import { firebase } from '../../config/firebase'
import { withRouter } from 'react-router'
import handleSignInErrors from './functions/handleSignInErrors'
import { AuthContext } from '../../App'
import Home from '../Home/Home'

function Auth({ ...router }) {
  const { user } = useContext(AuthContext)
  const [form, setForm] = useState(
    createForm({
      formPattern: {
        email: {
          field: {
            fieldId: 'email',
            fieldType: 'input',
            inputType: 'email',
            validationPattern: 'email',
            label: 'E-mail',
            required: true,
          },
          render: getFieldRenderObject(),
        },
        password: {
          field: {
            fieldId: 'password',
            fieldType: 'input',
            inputType: 'password',
            label: 'Пароль',
            required: true,
          },
          render: getFieldRenderObject(),
        },
      },
    })
  )
  const [isLoading, setIsLoading] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  const onSubmit = (e) => {
    e.preventDefault()

    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните обязательные поля',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)

    const { email, password } = getFormValues({ form })
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => router.history.push('/'))
      .catch((e) => {
        const errorMessage = handleSignInErrors({ code: e.code })
        if (errorMessage) {
          setStatusMessage({
            show: true,
            type: 'fail',
            message: errorMessage,
            closeAfter: 5000,
          })
        }
        setIsLoading(false)
      })
  }

  return user ? (
    <Home />
  ) : (
    <section className="Auth DefaultSection">
      <form onSubmit={onSubmit} className="AuthForm">
        <h4>Авторизация</h4>
        {statusMessage.show && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <FormRender
          sections={[{ fields: ['email', 'password'] }]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
        <div className="AuthForm-Buttons">
          <Button
            type="submit"
            title="Войти"
            theme="solid"
            fill="black"
            icon="long-arrow-right"
            iconPosition="right"
            size={48}
            isLoading={isLoading}
            onClick={onSubmit}
          />
        </div>
      </form>
    </section>
  )
}

export default withRouter(Auth)
