import { setHours, setMinutes } from 'date-fns'
import differenceInHours from 'date-fns/differenceInHours'
import { fi } from 'date-fns/locale'
import { ceil, find, floor, split, toNumber } from 'lodash'
import getFormValues from '../../../../utils/newforms/getFormValues'

const recountForm = ({ nextForm, carsData, optionsData, settings }) => {
  recountFullDaysAndHours({ nextForm })
  recountDeliveryCost({ nextForm, settings })
  recountOptionsPrice({ nextForm, optionsData })
  recountCarTarif({ nextForm, carsData, settings })

  return nextForm
}

const recountFullDaysAndHours = ({ nextForm }) => {
  const { delDate, delTime, retDate, retTime } = nextForm
  const [startingHour, startingMin] = split(delTime.value, ':')
  const [endingHour, endingMin] = split(retTime.value, ':')

  const dateStart = setMinutes(
    setHours(delDate.values, toNumber(startingHour)),
    toNumber(startingMin)
  )
  const dateEnd = setMinutes(
    setHours(retDate.values, toNumber(endingHour)),
    toNumber(endingMin)
  )

  const timeDiff = differenceInHours(dateEnd, dateStart)
  let fullDays = 0
  let fullHours = 0

  if (timeDiff <= 24) {
    fullDays = 1
    fullHours = 0
  } else {
    fullDays = floor(timeDiff / 24)
    fullHours = ceil(timeDiff - fullDays * 24)

    if (fullHours > 0 && fullHours <= 3) {
      fullDays += 0.5
    } else if (fullHours > 3) {
      fullDays += 1
    }
  }
  nextForm.counters.values.fullDays = fullDays
  nextForm.counters.values.fullHours = fullHours
}

const recountDeliveryCost = ({ nextForm, settings }) => {
  const { delTime, retTime, delType, retType } = nextForm

  const startingHour = toNumber(split(delTime.value, ':')[0])
  const startingMin = toNumber(split(delTime.value, ':')[1])
  const endingHour = toNumber(split(retTime.value, ':')[0])
  const endingMin = toNumber(split(retTime.value, ':')[1])

  let deliveryPrice = 0
  let returnPrice = 0

  if (
    (startingHour > 9 && startingHour < 20) ||
    (startingHour === 9 && startingMin >= 30) ||
    (startingHour === 20 && startingMin <= 30)
  ) {
    deliveryPrice = getCostByTypeInBusyHours(delType.value, settings)
  } else {
    deliveryPrice = getCostByTypeInSleepHours(delType.value, settings)
  }

  if (
    (endingHour > 9 && endingHour < 20) ||
    (endingHour === 9 && endingMin >= 30) ||
    (endingHour === 20 && endingMin <= 30)
  ) {
    returnPrice = getCostByTypeInBusyHours(retType.value, settings)
  } else {
    returnPrice = getCostByTypeInSleepHours(retType.value, settings)
  }

  nextForm.counters.values.delivery = deliveryPrice
  nextForm.counters.values.return = returnPrice
}

const getCostByTypeInBusyHours = (type, settings) => {
  switch (type) {
    case 'office':
      return +settings.officeBusy
    case 'airport':
      return +settings.airBusy
    default:
      return +settings.otherBusy
  }
}

const getCostByTypeInSleepHours = (type, settings) => {
  switch (type) {
    case 'office':
      return +settings.officeOther
    case 'airport':
      return +settings.airOther
    default:
      return +settings.otherOther
  }
}

const recountCarTarif = ({ nextForm, carsData, settings }) => {
  const car = find(carsData, ['carId', nextForm.carId.value])
  const { counters, transmission } = nextForm

  let pricePerDay = 0
  let zalog = 0
  let washing = settings.carwash

  if (car && transmission.value) {
    const carPrice = find(car.price, ['transmission', transmission.value])

    if (counters.values.fullDays <= 3) {
      pricePerDay = carPrice.to3
    } else if (counters.values.fullDays <= 14) {
      pricePerDay = carPrice.to14
    } else if (counters.values.fullDays <= 30) {
      pricePerDay = carPrice.to30
    } else {
      pricePerDay = carPrice.from30
    }
    zalog = carPrice.zalog
  }

  nextForm.counters.values.rentPrice = pricePerDay
  nextForm.counters.values.zalog = zalog
  // nextForm.counters.values.priceToPay.values =
  //   toNumber(pricePerDay) * toNumber(fullDays.values) +
  //   toNumber(zalog) +
  //   toNumber(washing) +
  //   toNumber(deliveryPrice.values) +
  //   toNumber(returnPrice.values) +
  //   toNumber(additionalOptionsCost.values)
}

const recountOptionsPrice = ({ nextForm, optionsData }) => {
  const values = getFormValues({ form: nextForm })
  const { additionalOptions, counters } = values
  let optionsTotalCost = 0
  additionalOptions.forEach((o) => {
    if (o.option) {
      const optionData = find(optionsData, ['optionId', o.option])
      if (optionData) {
        if (optionData.priceType === 'once') {
          optionsTotalCost += toNumber(optionData.price)
        } else {
          optionsTotalCost += counters.fullDays * toNumber(optionData.price)
        }
      }
    }
  })
  nextForm.counters.values.options = optionsTotalCost
}

export default recountForm
