import React, { useEffect, useState } from 'react'
import './CarsManager.sass'
import { withRouter } from 'react-router'
import StatusMessage, {
  useStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import Button from '../../../ui/Button/Button'
import FormRender from '../../../utils/newforms/render/FormRender'
import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'
import RouteComponent from '../../RouteComponent/RouteComponent'
import PhotoManager from '../../PhotoManager/PhotoManager'
import syncForm from '../../../utils/newforms/changed/syncForm'
import { createForm } from '../../../utils/newforms/createForm'
import isFormValid from '../../../utils/newforms/validation/isFormValid'
import { isEmpty, isNull, omit } from 'lodash'
import getFormValues from '../../../utils/newforms/getFormValues'
import { updateDoc } from '../../../utils/db/updateDoc'
import addDoc from '../../../utils/db/addDoc'
import getDoc from '../../../utils/db/getDoc'
import uploadPhotos from './functions/uploadPhotos'
import Spinner from '../../../ui/Spinner/Spinner'

function ToursManager({ ...router }) {
  const carId = router.match.params.id
  const [form, setForm] = useState(
    !carId ? createForm({ formPattern: new CarForm() }) : null
  )

  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (carId) {
      getDoc({ path: 'cars', docId: carId }).then((data) =>
        setForm(
          createForm({
            formPattern: new CarForm(),
            formData: data,
          })
        )
      )
    }
  }, [carId])

  // const prevLang = usePrevious(form)
  // useEffect(() => {
  //   if (
  //     form &&
  //     form.lang.value &&
  //     prevLang &&
  //     prevLang.lang.value &&
  //     form.lang.value !== prevLang.lang.value
  //   ) {
  //     if (tourId) {
  //       getDoc({ path: 'tours', docId: tourId }).then((data) =>
  //         setForm(
  //           createForm({
  //             formPattern: new TourForm(),
  //             formData: {
  //               ...data,
  //               ...data[form.lang.value],
  //               lang: form.lang.value,
  //             },
  //           })
  //         )
  //       )
  //     } else {
  //       setForm(
  //         createForm({
  //           formPattern: new TourForm(),
  //         })
  //       )
  //     }
  //   }
  // }, [form])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      console.log('here')
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля формы',
        closeAfter: 5000,
      })
    }
    if (isEmpty(form.photos.values)) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, добавъте фото авто',
        closeAfter: 5000,
      })
    }
    setIsLoading(true)

    const data = getFormValues({ form })
    const values = {
      ...data,
      updated: new Date(),
      photos: data.photos
        .filter((p) => !p.needUpload)
        .map((p) => omit(p, ['file', 'fileUrl'])),
    }

    const opRef = carId
      ? updateDoc({ path: 'cars', docId: carId, data: values })
      : addDoc({ path: 'cars', data: values })
    opRef.then((docId) =>
      uploadPhotos({ photos: data.photos, docId })
        .then(() => {
          router.history.push(`/cars/edit/${docId}`)
          setIsLoading(false)
          setStatusMessage({
            show: true,
            type: 'success',
            message: 'Данные сохранены',
            closeAfter: 5000,
          })
          window.scrollTo({ top: 0, behavior: 'smooth' })
        })
        .catch((error) => {
          console.log(
            '🚀 ~ file: ManageProjectPage.jsx ~ line 79 ~ onSubmit ~ error',
            error
          )
        })
    )
  }

  return (
    <RouteComponent
      title={carId ? 'Редактировать авто' : 'Добавить новое авто'}
    >
      {!isNull(form) ? (
        <div className="CarsManager-Content">
          {statusMessage.show && (
            <StatusMessage
              className="Site-StatusMessage"
              type={statusMessage.type}
              message={statusMessage.message}
            />
          )}
          <p className="Tech">
            <FormRender
              sections={[
                {
                  fields: ['isPublished'],
                },
              ]}
              form={form}
              setForm={setForm}
              errors={showErrors}
            />
          </p>
          <p className="Title">Основная информация</p>
          <div className="MainInfo">
            <FormRender
              sections={[
                {
                  fields: [
                    'title',
                    'class',
                    'modelYear',
                    'passangers',
                    'bagage',
                    'doors',
                    'wd',
                  ],
                },
              ]}
              form={form}
              setForm={setForm}
              errors={showErrors}
            />
          </div>

          <p className="Title">Стоимость аренды</p>
          <div className="PriceInfo">
            <FormRender
              sections={[
                {
                  fields: ['price'],
                },
              ]}
              form={form}
              setForm={setForm}
              errors={showErrors}
            />
          </div>
          <p className="Title">Фотогаллерея</p>
          <PhotoManager
            projectId={carId}
            formData={form ? form.photos.values : null}
            syncState={(moduleState) =>
              syncForm({
                propIdentifier: 'photos',
                form,
                setForm,
                newForm: moduleState,
              })
            }
          />
          <div className="Buttons">
            <Button
              title="Сохранить"
              theme="solid"
              fill="accent"
              iconPosition="right"
              size={48}
              isLoading={isLoading}
              onClick={onSubmit}
            />
            <Button
              title="Закрыть"
              theme="bounded"
              color="gray"
              border="gray"
              size={48}
              isLoading={isLoading}
              onClick={() => router.history.push('/cars/')}
            />
          </div>
        </div>
      ) : (
        <Spinner type="module" />
      )}
    </RouteComponent>
  )
}

class CarForm {
  constructor() {
    this.isPublished = {
      field: {
        fieldId: 'isPublished',
        fieldType: 'checkbox',
        label: 'Опубликовать',
      },
      render: getFieldRenderObject(),
    }
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        label: 'Марка, модель авто',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.class = {
      field: {
        fieldId: 'class',
        fieldType: 'select',
        label: 'Класс авто',
        required: true,
        getOptions: [
          { label: 'Бюджет', value: '1' },
          { label: 'Комфорт', value: '2' },
          { label: 'Бизнес', value: '3' },
          { label: 'Кроссовер', value: '4' },
          { label: 'Минивэн', value: '5' },
        ],
      },
      render: getFieldRenderObject(),
    }
    this.modelYear = {
      field: {
        fieldId: 'modelYear',
        fieldType: 'input',
        inputType: 'text',
        label: 'Модельный ряд',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.price = {
      field: {
        fieldId: 'price',
        fieldType: 'fieldset',
        fields: [
          {
            field: {
              fieldId: 'transmission',
              fieldType: 'select',
              label: 'Трансмиссия',
              getOptions: [
                { label: 'Автомат', value: 'A' },
                { label: 'Механика', value: 'M' },
              ],
              required: true,
              systemField: true,
              render: getFieldRenderObject(),
            },
          },
          {
            field: {
              fieldId: 'to3',
              fieldType: 'input',
              label: '1-3 сутки',
              required: true,
              systemField: true,
              render: getFieldRenderObject(),
            },
          },
          {
            field: {
              fieldId: 'to14',
              fieldType: 'input',
              label: '4-14 суток',
              required: true,
              systemField: true,
              render: getFieldRenderObject(),
            },
          },
          {
            field: {
              fieldId: 'to30',
              fieldType: 'input',
              label: '15-30 суток',
              required: true,
              systemField: true,
              render: getFieldRenderObject(),
            },
          },
          {
            field: {
              fieldId: 'from30',
              fieldType: 'input',
              label: '> 30 суток',
              required: true,
              systemField: true,
              render: getFieldRenderObject(),
            },
          },
          {
            field: {
              fieldId: 'zalog',
              fieldType: 'input',
              label: 'Залог',
              required: true,
              systemField: true,
              render: getFieldRenderObject(),
            },
          },
        ],
        label: 'Стоимость аренды',
        required: true,
      },
      render: getFieldRenderObject(),
    }

    this.passangers = {
      field: {
        fieldId: 'passangers',
        fieldType: 'input',
        label: 'Кол-во пассажиров',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.bagage = {
      field: {
        fieldId: 'bagage',
        fieldType: 'input',
        label: 'Кол-во багажа',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.doors = {
      field: {
        fieldId: 'doors',
        fieldType: 'input',
        label: 'Кол-во дверей',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.wd = {
      field: {
        fieldId: 'cooling',
        fieldType: 'select',
        getOptions: [
          { label: 'Да', value: '1' },
          { label: 'Нет', value: '0' },
        ],
        label: 'Полный привод',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.photos = {
      field: { fieldId: 'photos', value: [] },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.created = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export default withRouter(ToursManager)
