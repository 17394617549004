const parseLocationType = (location) => {
  switch (location) {
    case 'office':
      return 'Офис'
    case 'airport':
      return 'Аэропорт'
    default:
      return 'Свой адрес'
  }
}

export default parseLocationType
