import React, { useState, useEffect, useRef } from 'react'
import { AsYouType } from 'libphonenumber-js/max'
import cloneDeep from 'lodash/cloneDeep'
import PropTypes from 'prop-types'
import { fieldChanged } from '../../../utils/newforms/changed/fieldChanged'
import Field from '../Field'
import createCountrySelect from './helpers/createCountrySelect'

import './Phone.sass'
import Icon from '../../Icon/Icon'

function Phone({
  value,
  changed,
  defaultCountry,
  fieldClass,
  disabled,
  ...props
}) {
  const [country, setCountry] = useState(
    createCountrySelect(props.country, defaultCountry)
  )
  const [mannualyChangedCountry, setMannualyChangedCountry] = useState(false)
  const [formatedPhone, setFormatedPhone] = useState({ value: '', cursor: 0 })

  const inpRef = useRef()

  useEffect(() => {
    const countryClone = cloneDeep(country)
    const { asYouType } = updateFomatedPhone()

    if (asYouType.getNumber() && !mannualyChangedCountry) {
      const inputCountry = asYouType.getNumber().country
      countryClone.value = inputCountry || defaultCountry
    }

    if (!asYouType.getNumber() && mannualyChangedCountry) {
      setMannualyChangedCountry(false)
    }

    if (!asYouType.getNumber() && !mannualyChangedCountry) {
      countryClone.value = defaultCountry
    }

    setCountry(countryClone)

    inpRef.current.selectionStart = formatedPhone.cursor
    inpRef.current.selectionEnd = formatedPhone.cursor
  }, [value])

  useEffect(() => {
    const { formatedValue } = updateFomatedPhone()
    props.initCountry(country.value)
    changed({ target: { value: formatedValue }, country: country.value })
  }, [country.value])

  const updateFomatedPhone = () => {
    const formatedPhoneClone = cloneDeep(formatedPhone)
    const asYouType = new AsYouType(country.value || defaultCountry)
    const formatedValue = asYouType.input(value.value)
    setFormatedPhone({ ...formatedPhoneClone, value: formatedValue })
    return { asYouType, formatedValue }
  }

  const onCountryChanged = (event) => {
    const countryClone = cloneDeep(country)
    setMannualyChangedCountry(true)
    const newCountry = fieldChanged(countryClone, event)
    setCountry(newCountry)
  }

  const iconClasses = ['Field-Icon']
  if (props.config.iconClass) {
    iconClasses.push(props.config.iconClass)
  }

  return (
    <div className="Phone">
      <Field
        beforeContent={
          <span
            className={['fp', 'CountryFlag', country.value.toLowerCase()].join(
              ' '
            )}
          />
        }
        key={country.fieldId}
        {...country}
        changed={onCountryChanged}
        isChangable={!disabled}
      />
      <div className={fieldClass.join(' ')}>
        <input
          ref={inpRef}
          {...props.config}
          onChange={(e) => {
            const val = e.target.value
            const asYouType = new AsYouType(country.value || defaultCountry)
            let formatedString = asYouType.input(val)
            let cursorPos = e.target.selectionStart
            if (
              (!formatedString[cursorPos + 1] ||
                formatedString[cursorPos + 1] === '(' ||
                formatedString[cursorPos + 1] === ')') &&
              formatedString.length
            ) {
              if (formatedString.length > formatedPhone.value.length) {
                cursorPos += 2
              } else if (
                formatedString[cursorPos] === ')' ||
                formatedString[cursorPos] === '('
              ) {
                formatedString = formatedString.slice(0, -2)
              }
            }
            setFormatedPhone({ value: formatedString, cursor: cursorPos })
            changed({
              target: { value: formatedString },
              country: country.value,
            })
          }}
          value={formatedPhone.value}
          disabled={disabled}
        />
        {props.config.icon ? (
          <span
            onClick={
              props.config.useDropValueButton
                ? props.dropValue
                : props.config.iconFunc
            }
            className={iconClasses.join(' ')}
          >
            <Icon
              name={props.config.icon}
              size={props.config.iconSize && props.config.iconSize}
            />
          </span>
        ) : null}
        <span className="Field-Bar" />
        <label>{props.config.label}</label>
        {props.showErrors && props.error ? (
          <div className="Field-Error">{props.error}</div>
        ) : null}
      </div>
    </div>
  )
}

export default Phone

Phone.propTypes = {
  value: PropTypes.string.isRequired,
  changed: PropTypes.func.isRequired,
  defaultCountry: PropTypes.string.isRequired,
  fieldClass: PropTypes.array.isRequired,
}
