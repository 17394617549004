import React, { useEffect, useState } from 'react'
import './OrderManager.sass'
import { withRouter } from 'react-router'
import StatusMessage, {
  useStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import Button from '../../../ui/Button/Button'
import RouteComponent from '../../RouteComponent/RouteComponent'
import {
  cloneDeep,
  find,
  includes,
  isArray,
  isEmpty,
  isString,
  split,
} from 'lodash'
import Spinner from '../../../ui/Spinner/Spinner'
import InfoLine from '../../../ui/InfoLine/InfoLine'
import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'
import FormRender from '../../../utils/newforms/render/FormRender'
import { createForm } from '../../../utils/newforms/createForm'
import useCallbacks from '../../../hooks/useCallbacks'
import getCollection from '../../../utils/db/getCollection'
import DatePicker from '../../../ui/DatePicker/DatePicker'
import convertLocations from './functions/convertLocations'
import {
  formatDateAsDayMontYearHHMM,
  formatDateAsDayMonthYear,
} from '../../../utils/date/dateFormat'
import recountForm from './functions/recountForm'
import isFormValid from '../../../utils/newforms/validation/isFormValid'
import getFormValues from '../../../utils/newforms/getFormValues'
import getDoc from '../../../utils/db/getDoc'
import { updateDoc } from '../../../utils/db/updateDoc'
import addDoc from '../../../utils/db/addDoc'
import promiseAllValues from '../../../utils/promiseAllValues'
import convertAdditionalOptions from './functions/convertAdditionalOptions'
import { countOrderPrice } from '../OrderDetails/functions/parseOrderData'

function OrderManager({ ...router }) {
  const orderId = router.match.params.id
  const [orderData, setOrderData] = useState(null)
  const [carsData, setCarsData] = useState(null)
  const [optionsData, setOptionsData] = useState(null)
  const [settings, setSettings] = useState(null)

  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  const [isLoading, setIsLoading] = useState(false)
  const [form, setForm] = useState(null)

  useEffect(() => {
    promiseAllValues({
      order: getDoc({
        path: 'newOrders',
        docId: orderId,
        docIdName: 'orderId',
      }),
      cars: getCollection({
        path: 'cars',
        orderBy: { fieldPath: 'title', orderDir: 'asc' },
        docIdName: 'carId',
      }),
      options: getCollection({
        path: 'options',
        orderBy: { fieldPath: 'title_ru', orderDir: 'asc' },
        docIdName: 'optionId',
      }),
      settings: getDoc({
        path: 'settings',
        docId: 'BqOykJugZ5mnBAAPocze',
        docIdName: 'id',
      }),
    }).then((result) => {
      result.order.delDate = result.order.delDate.seconds * 1000
      result.order.retDate = result.order.retDate.seconds * 1000

      setOrderData(result.order)
      setCarsData(result.cars)
      setOptionsData(result.options)
      setSettings(result.settings)
    })
  }, [])

  useEffect(() => {
    if (
      !isEmpty(orderData) &&
      !isEmpty(carsData) &&
      !isEmpty(optionsData) &&
      !isEmpty(settings)
    ) {
      // const { dateFrom, dateTo } = orderData

      // if (isString(dateFrom) && isString(dateTo)) {
      //   const start = split(dateFrom, '.')
      //   const end = split(dateTo, '.')
      //   orderData.dateFrom = new Date(start[2], start[1] - 1, start[0])
      //   orderData.dateTo = new Date(end[2], end[1] - 1, end[0])
      // }

      // convertLocations({ orderData })

      // if (!isArray(orderData.additionalOptions)) {
      //   convertAdditionalOptions({ orderData })
      // }

      // if (orderData.carId && !orderData.kpp) {
      //   orderData.kpp = includes(orderData.car, 'AКПП') ? 'A' : 'M'
      //   orderData.car = orderData.carId
      // }

      updateForm(
        createForm({
          formPattern: new OrderForm(),
          formData: orderData,
          methods: {
            getCars: () =>
              Promise.resolve(
                carsData.map((c) => {
                  return {
                    label: `${c.title} (${c.modelYear})`,
                    value: c.carId,
                  }
                })
              ),
            getCarKpp: () => handlers.getCarKpp(),
            getAdditionalOptions: () =>
              getCollection({
                path: 'options',
                docIdName: 'optionId',
              }).then((data) => {
                return data
                  .filter((o) => o.isDeleted !== true && o.isActive == true)
                  .map((o) => ({
                    label: o.title_ru,
                    value: o.optionId,
                  }))
              }),
          },
        })
      )
    }
  }, [orderData, carsData, optionsData, settings])

  const updateForm = (nextForm) => {
    setForm(recountForm({ nextForm, carsData, optionsData, settings }))
  }

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля формы',
        closeAfter: 5000,
      })
    }
    setIsLoading(true)
    const values = getFormValues({ form })

    updateDoc({
      path: 'newOrders',
      docId: orderId,
      data: {
        ...values,
        delDate: new Date(values.delDate),
        retDate: new Date(values.retDate),
      },
    })
      .then(() => {
        setIsLoading(false)
        setStatusMessage({
          show: true,
          type: 'success',
          message: 'Данные сохранены',
          closeAfter: 5000,
        })
        window.scrollTo({ top: 0, behavior: 'smooth' })
      })
      .catch((error) => {
        console.log(
          '🚀 ~ file: ManageProjectPage.jsx ~ line 79 ~ onSubmit ~ error',
          error
        )
      })
  }

  const handlers = useCallbacks((callbacks) => {
    callbacks.getCarKpp = () => {
      if (form && form.carId.value) {
        const car = find(carsData, ['carId', form.carId.value])
        if (car) {
          return Promise.resolve(
            car.price.map((p) => ({
              label: p.transmission,
              value: p.transmission,
            }))
          )
        }
        return Promise.resolve([])
      }
      return Promise.resolve([])
    }
    callbacks.getForm = () => form
  })

  const onDateChange = ([start, end]) => {
    const clone = cloneDeep(form)
    clone.delDate.values = start
    clone.retDate.values = end
    updateForm(clone)
  }

  return (
    <RouteComponent
      title={
        !isEmpty(orderData)
          ? `Редактировать заказ  - от ${formatDateAsDayMontYearHHMM(
              orderData.created
            )}`
          : ''
      }
    >
      <div className="OrderManager-Content">
        {statusMessage.show && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        {!isEmpty(form) ? (
          <>
            <div className="OrderManager-Tech">
              <p className="Title">Данные заказа</p>
              <FormRender
                sections={[
                  {
                    fields: ['carId', 'transmission'],
                  },
                ]}
                form={form}
                setForm={updateForm}
                errors={showErrors}
              />
              <p className="SubTitle">Срок аренды</p>
              <DatePicker
                selected={form.delDate.values}
                startDate={form.delDate.values}
                endDate={form.retDate.values}
                selectsRange
                onChange={onDateChange}
                monthsShown={2}
                minDate={new Date()}
              />
              <FormRender
                sections={[
                  {
                    fields: ['delTime', 'retTime'],
                  },
                ]}
                form={form}
                setForm={updateForm}
                errors={showErrors}
              />
              <div className="Locations">
                <FormRender
                  sections={[
                    {
                      fields: [
                        'delType',
                        'delLocation',
                        'retType',
                        'retLocation',
                      ],
                    },
                  ]}
                  form={form}
                  setForm={updateForm}
                  errors={showErrors}
                />
              </div>
              <div className="AdditionalOptions">
                <FormRender
                  sections={[
                    {
                      fields: ['additionalOptions'],
                    },
                  ]}
                  form={form}
                  setForm={updateForm}
                  errors={showErrors}
                />
              </div>
              <div className="PaymentType">
                <FormRender
                  sections={[
                    {
                      fields: ['paymentType'],
                    },
                  ]}
                  form={form}
                  setForm={updateForm}
                  errors={showErrors}
                />
              </div>
              <div className="Counters">
                <p className="Title">Расчетные данные по заказу</p>
                <InfoLine
                  title="Срок аренды"
                  content={`${formatDateAsDayMonthYear({
                    seconds: form.delDate.values / 1000,
                  })} в ${form.delTime.value} - ${formatDateAsDayMonthYear({
                    seconds: form.retDate.values / 1000,
                  })} в ${form.retTime.value} (Расчетных cуток: ${
                    form.counters.values.fullDays
                  })`}
                />
                <InfoLine
                  title="Подача и возврат"
                  content={`${form.delLocation.value} (${form.counters.values.delivery} руб.) - ${form.retLocation.value} (${form.counters.values.return} руб.)`}
                />
                <InfoLine
                  title="Тариф авто за сутки"
                  content={`${form.counters.values.rentPrice} руб.`}
                />
                <InfoLine
                  title="Стоимость залога"
                  content={`${form.counters.values.zalog} руб.`}
                />
                <InfoLine title="Мойка" content={`${settings.carwash} руб.`} />

                <InfoLine
                  title="Стоимость доп. опций"
                  content={`${form.counters.values.options} руб.`}
                />
                <InfoLine
                  title="Общая стоимость по договору"
                  content={`${countOrderPrice({
                    counters: form.counters.values,
                  })} руб.`}
                />
              </div>
            </div>
            <div className="Client">
              <p className="Title">Данные клиента</p>
              <FormRender
                sections={[
                  {
                    fields: [
                      'userFio',
                      'userBdate',
                      'userEmail',
                      'userPhone',
                      'userComment',
                    ],
                  },
                ]}
                form={form}
                setForm={updateForm}
                errors={showErrors}
              />
              <p className="Title">Паспорт клиента</p>
              <FormRender
                sections={[
                  {
                    fields: [
                      'passportSeria',
                      'passportKem',
                      'passportDate',
                      'passportAddress',
                    ],
                  },
                ]}
                form={form}
                setForm={updateForm}
                errors={showErrors}
              />
              <p className="Title">Вод. удостоверение</p>
              <FormRender
                sections={[
                  {
                    fields: ['vodSeria', 'vodStage'],
                  },
                ]}
                form={form}
                setForm={updateForm}
                errors={showErrors}
              />
            </div>
          </>
        ) : (
          <Spinner type="module" />
        )}
        <div className="Buttons">
          <Button
            title="Сохранить"
            theme="solid"
            fill="accent"
            iconPosition="right"
            size={48}
            isLoading={isLoading}
            onClick={onSubmit}
          />
          <Button
            title="Закрыть"
            theme="bounded"
            color="gray"
            border="gray"
            size={48}
            onClick={() => router.history.push(`/orders/view/${orderId}`)}
          />
        </div>
      </div>
    </RouteComponent>
  )
}

class OrderForm {
  constructor() {
    this.carId = {
      field: {
        fieldId: 'carId',
        fieldType: 'select',
        label: 'Выбирете автомобиль',
        required: true,
        getOptions: 'getCars',
      },
      render: getFieldRenderObject(),
    }
    this.transmission = {
      field: {
        fieldId: 'transmission',
        fieldType: 'select',
        label: 'Выбирете КПП',
        required: true,
        getOptions: 'getCarKpp',
      },
      render: getFieldRenderObject({ dropOnFieldIdValueChanged: 'carId' }),
    }
    this.delDate = {
      field: { fieldId: 'delDate' },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.retDate = {
      field: { fieldId: 'retDate' },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.delTime = {
      field: {
        fieldId: 'delTime',
        fieldType: 'input',
        inputType: 'text',
        label: 'Время подачи',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.retTime = {
      field: {
        fieldId: 'retTime',
        fieldType: 'input',
        inputType: 'text',
        label: 'Время возврата',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.delType = {
      field: {
        fieldId: 'delLocationType',
        fieldType: 'select',
        label: 'Место подачи авто',
        getOptions: [
          { label: 'Аэропорт', value: 'airport' },
          { label: 'Офис', value: 'office' },
          { label: 'Другой адрес', value: 'other' },
        ],
      },
      render: getFieldRenderObject(),
    }
    this.delLocation = {
      field: {
        fieldId: 'delLocation',
        fieldType: 'input',
        inputType: 'text',
        label: 'Адрес подачи авто',
      },
      render: getFieldRenderObject({
        byDefault: false,
        ifFieldId: 'delType',
        value: 'other',
        dropOnFieldIdValueChanged: 'delType',
      }),
    }
    this.retType = {
      field: {
        fieldId: 'retType',
        fieldType: 'select',
        label: 'Место возврата авто',
        getOptions: [
          { label: 'Аэропорт', value: 'airport' },
          { label: 'Офис', value: 'office' },
          { label: 'Другой адрес', value: 'other' },
        ],
      },
      render: getFieldRenderObject(),
    }
    this.retLocation = {
      field: {
        fieldId: 'retLocation',
        fieldType: 'input',
        inputType: 'text',
        label: 'Адрес возврата авто',
      },
      render: getFieldRenderObject({
        byDefault: false,
        ifFieldId: 'retType',
        value: 'other',
        dropOnFieldIdValueChanged: 'retType',
      }),
    }
    this.additionalOptions = {
      field: {
        fieldId: 'additionalOptions',
        fieldType: 'fieldset',
        fields: [
          {
            field: {
              fieldId: 'option',
              fieldType: 'select',
              label: 'Дополнительная опция',
              required: false,
              getOptions: 'getAdditionalOptions',
              render: getFieldRenderObject(),
            },
          },
        ],
        label: 'Дополнительные опции',
        // required: false,
      },
      render: getFieldRenderObject(),
    }
    this.paymentType = {
      field: {
        fieldId: 'paymentType',
        fieldType: 'select',
        label: 'Способ оплаты',
        required: true,
        getOptions: [
          { label: 'Наличными при получение', value: 'cash' },
          { label: 'Онлайн оплата ', value: 'card' },
        ],
      },
      render: getFieldRenderObject(),
    }
    this.userFio = {
      field: {
        fieldId: 'userFio',
        fieldType: 'input',
        inputType: 'text',
        label: 'Фио клиента',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.userBdate = {
      field: {
        fieldId: 'userBdate',
        fieldType: 'input',
        inputType: 'text',
        label: 'Дата рождения',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.userEmail = {
      field: {
        fieldId: 'userEmail',
        fieldType: 'input',
        inputType: 'email',
        label: 'E-mail',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.userPhone = {
      field: {
        fieldId: 'userPhone',
        fieldType: 'phone',
        label: 'Контактный телефон',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.userFact = {
      field: {
        fieldId: 'userFact',
        fieldType: 'input',
        inputType: 'text',
        label: 'Адрес фактического проживания',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.userComment = {
      field: {
        fieldId: 'userComment',
        fieldType: 'textarea',
        label: 'Доп. инфо',
        required: false,
      },
      render: getFieldRenderObject(),
    }
    this.passportSeria = {
      field: {
        fieldId: 'passportSeria',
        fieldType: 'input',
        inputType: 'text',
        label: 'Серия, номер',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.passportKem = {
      field: {
        fieldId: 'passportKem',
        fieldType: 'input',
        inputType: 'text',
        label: 'Кем выдан',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.passportDate = {
      field: {
        fieldId: 'passportDate',
        fieldType: 'input',
        inputType: 'text',
        label: 'Дата выдачи',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.passportAddress = {
      field: {
        fieldId: 'passportAddress',
        fieldType: 'input',
        inputType: 'text',
        label: 'Адрес регистрации по паспорту',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.vodSeria = {
      field: {
        fieldId: 'vodSeria',
        fieldType: 'input',
        inputType: 'text',
        label: 'Серия, номер',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.vodStage = {
      field: {
        fieldId: 'vodStage',
        fieldType: 'input',
        inputType: 'text',
        label: 'Водительский стаж',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.created = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.counters = {
      field: {
        fieldId: 'counters',
        value: {
          carwash: 0,
          delivery: 0,
          fullDays: 0,
          fullHours: 0,
          options: 0,
          rentPrice: 0,
          return: 0,
          zalog: 0,
        },
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export default withRouter(OrderManager)
