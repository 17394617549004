import React from 'react'
import { Route } from 'react-router-dom'
import OptionsList from './OptionsList/OptionsList'
import OptionsManager from './OptionsManager/OptionsManager'

function Routes() {
  return (
    <>
      <Route path="/options" exact component={OptionsList} />
      <Route path="/options/add" exact component={OptionsManager} />
      <Route path="/options/edit/:id" exact component={OptionsManager} />
    </>
  )
}

export default Routes
