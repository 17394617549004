import { format } from 'date-fns'
import ru from 'date-fns/locale/ru'

const formatDateAsMonthNameAndYear = (date) =>
  format(date.seconds * 1000, 'LLLL, yyyy', { locale: ru })
const formatDateAsDayMontYearHHMM = (date) =>
  format(date.seconds * 1000, 'dd LLL yyyy в HH:mm', { locale: ru })
const formatDateAsDayMonthYear = (date) =>
  format(date.seconds * 1000, 'dd.LL.yyyy', { locale: ru })
const formatDateAsDayMonthNameYear = (date) =>
  format(date.seconds * 1000, 'dd MMMM yyyy', { locale: ru })
const formatDateAsMonthNameYear = (date) =>
  format(date.seconds * 1000, 'LLLL yyyy', { locale: ru })
const formatDateAsMonthName = (date) =>
  format(date.seconds * 1000, 'LLLL', { locale: ru })
const formatDateAsYYYYMMDD = (date) =>
  format(date.seconds * 1000, 'yyyy-LL-dd', { locale: ru })
const formatDateAsHHMM = (date) =>
  format(date.seconds * 1000, 'HH:mm', { locale: ru })
export {
  formatDateAsMonthNameAndYear,
  formatDateAsDayMontYearHHMM,
  formatDateAsDayMonthNameYear,
  formatDateAsMonthNameYear,
  formatDateAsMonthName,
  formatDateAsYYYYMMDD,
  formatDateAsHHMM,
  formatDateAsDayMonthYear,
}
