import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyDgYzr7C64kKYSpSdc2ryQcWjkks0PDrtA',
  authDomain: 'autocp-5726c.firebaseapp.com',
  projectId: 'autocp-5726c',
  storageBucket: 'autocp-5726c.appspot.com',
  messagingSenderId: '735507123142',
  appId: '1:735507123142:web:997d0ad9112094b746c0c7',
}

firebase.initializeApp(firebaseConfig)

const firebaseSideEffect = firebase.initializeApp(
  firebaseConfig,
  'Side Effect instance'
)

const db = firebase.firestore()
const storageRef = firebase.storage().ref()
const { storage } = firebase
const functions = firebase.app().functions('europe-west3')

export { db, storage, storageRef, firebase, firebaseSideEffect, functions }
