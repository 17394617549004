import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import OrdersList from './OrdersList/OrdersList'
import OrderDetails from './OrderDetails/OrderDetails'
import OrderManager from './OrderManager/OrderManager'

function Routes() {
  return (
    <>
      <Route exact path="/">
        <Redirect to="/orders" />
      </Route>
      <Route
        path={['/orders', '/orders/page/:page']}
        exact
        component={OrdersList}
      />
      {/* <Route path="/tours/add" exact component={ToursManager} /> */}
      <Route path="/orders/view/:id" exact component={OrderDetails} />
      <Route path="/orders/edit/:id" exact component={OrderManager} />
    </>
  )
}

export default Routes
