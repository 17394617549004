import React, { useEffect, useState } from 'react'
import RouteComponent from '../../RouteComponent/RouteComponent'
import Spinner from '../../../ui/Spinner/Spinner'
import InfoLine from '../../../ui/InfoLine/InfoLine'
import './RulesList.sass'
import { isEmpty, orderBy } from 'lodash'
import Icon from '../../../ui/Icon/Icon'
import { withRouter } from 'react-router'
import Button from '../../../ui/Button/Button'
import { db } from '../../../config/firebase'
import { formatDateAsDayMontYearHHMM } from '../../../utils/date/dateFormat'
import DeletePopUp from '../../../ui/PopUp/DeletePopUp/DeletePopUp'
import onOptionDelete from '../RulesManager/functions/deleteRule'
import getCollection from '../../../utils/db/getCollection'

function RulesList({ ...router }) {
  const [rulesArr, setRulesArr] = useState(null)
  const [deleteRule, setDeleteRule] = useState(null)

  useEffect(() => {
    db.collection('rules').onSnapshot((docs) => {
      const data = []
      docs.forEach((d) => data.push({ ruleId: d.id, ...d.data() }))
      setRulesArr(
        orderBy(
          data.filter((d) => !d.isDeleted && true),
          ['position'],
          ['asc']
        )
      )
    })
  }, [])

  const syncRules = () => {
    getCollection({ path: 'rules', docIdName: 'ruleId' }).then((data) => {
      const filtered = data.filter((o) => !o.isDeleted && true)
      const json = JSON.stringify({ rules: filtered })
      fetch(
        'https://autoprokat51.ru/syncRules.php?token=b2a23e0e779cc697a90a283a54ff898c',
        {
          method: 'POST',
          body: json,
        }
      ).then((resp) => {
        console.log('🚀 ~ file: RulesList.jsx:46 ~ ).then ~ resp', resp.json())
      })
    })
  }

  return (
    <RouteComponent
      title="Условия аренды"
      controlls={
        <>
          <Button
            title="Синхронизировать условия"
            icon="cloud-upload"
            theme="bounded"
            border="gray"
            color="gray"
            size={28}
            style={{ marginRight: '16px' }}
            onClick={syncRules}
          />
          <Button
            title="Добавить условие"
            icon="plus"
            fill="accent"
            theme="solid"
            type="navlink"
            path="/rules/add"
            size={28}
          />
        </>
      }
    >
      {deleteRule && (
        <DeletePopUp
          title="Удаление условия"
          warningMessage={`Вы уверены, что хотите удалить условие "${deleteRule.title}"?`}
          closePopUp={() => setDeleteRule(null)}
          submitDelete={() => onOptionDelete({ ruleId: deleteRule.ruleId })}
        />
      )}
      <div className="OptionsList">
        {rulesArr ? (
          isEmpty(rulesArr) ? (
            <p className="EmptyText">Нет условий</p>
          ) : (
            rulesArr.map((o) => (
              <RuleTile
                key={o.ruleId}
                {...o}
                onEdit={() => router.history.push(`/rules/edit/${o.ruleId}`)}
                onDelete={() =>
                  setDeleteRule({
                    title: o.title_ru,
                    ruleId: o.ruleId,
                  })
                }
              />
            ))
          )
        ) : (
          <Spinner type="module" />
        )}
      </div>
    </RouteComponent>
  )
}

function RuleTile(data) {
  return (
    <div className="OptionTile" onDoubleClick={data.onEdit}>
      <div className="CarDate">{formatDateAsDayMontYearHHMM(data.updated)}</div>
      <div className="Content">
        <p className="Title">{data.title_ru}</p>
      </div>

      <div className="Options">
        <div className="OptionButton Accent" onClick={data.onEdit}>
          <Icon name="pencil-alt" weight="solid" />
        </div>
        <div className="OptionButton Red" onClick={data.onDelete}>
          <Icon name="trash-alt" />
        </div>
      </div>
    </div>
  )
}

export default withRouter(RulesList)
